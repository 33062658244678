import React from "react"
import { graphql } from "gatsby"

import { GatsbyPage } from "src/types"
import Layout from "src/layout"
import TreeImage from "src/svg/Tree"
import {
  Section,
  Subtitle,
  Title,
  Description,
  SectionTitle,
  Text,
  RowSection,
  Column,
  Answer,
  Illustration,
  Content,
} from "src/components/styled"
import { Splash, Parallax } from "src/components/Parallax"
import { scrollToRef } from "src/helpers/window"

const ValuesPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Valeurs">
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
      </Splash>
      <Content>
        <Section>
          <Subtitle>DES ATELIERS ET DES VALEURS…</Subtitle>
          <Title ref={scrollToRef}>
            <span>Diminuer le gaspillage du bois et apprendre à construire ensemble</span>
          </Title>
          <Description>
            Un bel objet est parfois plus convaincant qu’un long discours. Ainsi nous sensibilisons le public à
            l’économie circulaire par la mise en action. En valorisant des chutes de bois destinées à être jetées, nos
            ateliers cultivent le plaisir de construire par soi-même et contribuent ainsi à transformer le regard sur
            ces matières mises au rebus.
            <br />
            <br />
            S’incrivant dans un circuit court, nous collectons le bois en Ile-de-France et nous valorisons en moyenne 8
            tonnes de bois par an.
          </Description>
        </Section>
        <RowSection>
          <Column>
            <SectionTitle>QUOI ?</SectionTitle>
            <Answer>Des chutes de bois sauvées de la benne à ordures</Answer>
            <Text>
              Nous collectons du bois issus du démontage de scénographies d’expositions et d’évènements en région
              parisienne, ainsi que des chutes provenant d’ateliers de menuiserie. Encore trop souvent et malgré leur
              bon état, ces panneaux de bois sont détruits alors qu’ils peuvent encore servir.
            </Text>
            <Text>
              Nous collectons ce bois dans un rayon de 15 km autour de notre atelier, afin d’assurer une valorisation en
              circuit court de ces matériaux.
            </Text>
          </Column>
          <Column>
            <Illustration file={data.wood.childImageSharp} />
          </Column>
        </RowSection>
        <RowSection>
          <Column>
            <SectionTitle>POUQUOI ?</SectionTitle>
            <Answer>
              Pour contribuer aux changements de comportements qui deviennent indispensables à la transition écologique
            </Answer>
            <Text>
              Consommer moins et mieux, réduire le gaspillage en réemployant des matériaux, utiliser les ressources
              locales plutôt que des matériaux venus de loin, apprendre à fabriquer par soi-même pour être autonome et
              capable de réparer… sont autant de comportements transmis dans nos ateliers.
            </Text>
            <Text>
              Du point de vue climatique, la réutilisation des produits bois est également souhaitable car elle permet
              de prolonger le stockage du carbone prélevé et stocké en forêt, qui serait libéré en cas d’incinération.
            </Text>
          </Column>
          <Column>
            <TreeImage />
          </Column>
        </RowSection>
        <RowSection>
          <Column>
            <SectionTitle>QUAND ?</SectionTitle>
            <Answer>Depuis 2013, avec le réemploi d’une scénographie d’exposition au Pavillon de l’Arsenal…</Answer>
            <Text>
              L’idée des ateliers est née en 2013 lors de l’exposition <em>Matière Grise</em> au Pavillon de l’Arsenal.
              Pendant un an, nous avons valorisé les scénographies de ce musée. Puis, grâce au soutien de l’ADEME entre
              2014 et 2017, les ateliers deviennent itinérants. Ils s’implantent dans les musées et réemploient les
              chutes de scénographies de ces lieux d’accueil. À partir de 2018, les ateliers s’installent sous une des
              voutes du Viaduc des Arts, et deviennent un lieu dédié à l’accueil du public et au stockage du bois.
            </Text>
          </Column>
          <Column>
            <Illustration file={data.concept.childImageSharp} />
          </Column>
        </RowSection>
      </Content>
    </Layout>
  )
}

export default ValuesPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "values/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    splashMobile: file(relativePath: { eq: "values/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wood: file(relativePath: { eq: "values/planches.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    concept: file(relativePath: { eq: "values/concept.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
